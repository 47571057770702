import { Button, Col, Container, Row } from "react-bootstrap"
import { FaMobileAlt } from "react-icons/fa"
import { MdQuickreply, MdOutlineSecurity } from "react-icons/md"
import { AiFillControl, AiFillCheckCircle } from "react-icons/ai"
import { SiSpringsecurity } from "react-icons/si"
import { Fade } from "react-awesome-reveal"
import phones from "../../assets/images/eventImg.png"
import EventCard from "../../components/EventCard"

const Organizers = [
  {
    icon: MdQuickreply,
    title: "Efficient",
    text: "Easily set up ticketing for your event and let attendees immediately start purchasing using their mobile money accounts.",
  },
  {
    icon: MdOutlineSecurity,
    title: "Eliminate Ticket Fraud",
    text: "Every ticket sold is verifiable and can only be used once. You can also view the scan activity of every ticket.",
  },
  {
    icon: AiFillControl,
    title: "Fully Transparent",
    text: "You sell it, you set the price. You will see all tickets sold and how much you made from each sale.",
  },
]

const Attendees = [
  {
    icon: FaMobileAlt,
    title: "Easy to Use",
    text: "Get tickets to events from your phone. Use your mobile money account to buy tickets and get immediate access to them.",
  },
  {
    icon: SiSpringsecurity,
    title: "Safe & Secure",
    text: "Your tickets will never be lost even if you lose your phone. All you have to do is log into Ventix on any phone and your tickets will be right there.",
  },
  {
    icon: AiFillCheckCircle,
    title: "Authenticity Guaranteed",
    text: "Every ticket you purchase on Ventix is authentic and valid. Rest assured that your spot at the event is guaranteed.",
  },
]

export default function EventSection() {
  // const navigate = useNavigate()

  return (
    <div
      className="event-bkg"
      id="events"
    >
      <Container className="pt-2 text-white">
        <h1 className="text-center mt-2">Events & Services</h1>
        <Fade>
          <Row>
            <Col
              md={4}
              className="my-auto"
            >
              <h4 className="text-center pb-2">Event Organizers</h4>
              {Organizers.map((item, i) => (
                <EventCard
                  key={i}
                  {...item}
                />
              ))}
              <div className="text-center mt-4">
                <h5>Check out our organizer website for more info</h5>
                <a
                  href="https://www.ventix.biz"
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Go to Organizer Website {">>"}
                </a>
              </div>
            </Col>

            <Col
              md={4}
              className="my-auto"
            >
              <img
                src={phones}
                alt="phones"
                className="img-fluid"
              />
            </Col>

            <Col
              md={4}
              className="my-auto"
            >
              <h4 className="text-center">Event Attendees</h4>
              {Attendees.map((item, i) => (
                <EventCard
                  key={i}
                  {...item}
                />
              ))}
            </Col>
          </Row>
        </Fade>
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <Button
              className="mt-5 btn-lg learn-btn text-black bg-white"
              size="lg"
              href={"/events/all"}
            >
              View Events
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
