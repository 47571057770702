import React from "react"
import appStore from "../assets/images/app-store.png"
import playStore from "../assets/images/play-store.png"
import headerImage from "../assets/images/Event-main-screen.png"
import ventixWallet from "../assets/images/ventixWallet.png"
import trivia from "../assets/images/trival.png"
import dollarCard from "../assets/images/dollarCard.png"
import { Container, Image, Carousel, Button } from "react-bootstrap"
import { downLoadVisit } from "../analytics"

function Header({ source }) {
  return (
    <div
      className={"header-bkg"}
      id={"home"}
    >
      <Container className={"text-white"}>
        <Carousel
          interval={3000}
          controls={false}
        >
          <Carousel.Item>
            <div className="row">
              <div className="col-lg-6 my-auto">
                <h1>Digitize Your Events</h1>
                <p className={"mt-4"}>
                  Ventix is the easiest way to sell tickets, manage crowds and
                  keep revenue. With the Ventix app, event organizers can start
                  selling verifiable tickets to the public in just a few taps.
                  People can discover events near them and purchase tickets with
                  their Ventix Cash, mobile money accounts, credit or debit
                  cards.
                </p>
                <div className={"row pt-4"}>
                  <p>
                    <Image
                      className={"pointer"}
                      fluid={false}
                      src={playStore}
                      height={"auto"}
                      width={"150px"}
                      onClick={() => downLoadVisit("android", source)}
                    />
                    <span className={"mx-2"}></span>
                    <Image
                      className={"pointer"}
                      fluid={false}
                      src={appStore}
                      height={"auto"}
                      width={"150px"}
                      onClick={() => downLoadVisit("ios", source)}
                    />
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <Image
                    fluid={true}
                    src={headerImage}
                    alt="tickets image"
                  />
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="row">
              <div className="col-lg-6 my-auto">
                <h1>Shop Anywhere with your Ventix Virtual U$D Card</h1>
                <p className={"mt-4"}>
                  Load up your virtual dollar card and make payment at over
                  twenty million online stores, apps, courses, website and more.
                </p>
                <div className={"row pt-4"}>
                  <p>
                    <Image
                      className={"pointer"}
                      fluid={false}
                      src={playStore}
                      height={"auto"}
                      width={"150px"}
                      onClick={() => downLoadVisit("android", source)}
                    />
                    <span className={"mx-2"}></span>
                    <Image
                      className={"pointer"}
                      fluid={false}
                      src={appStore}
                      height={"auto"}
                      width={"150px"}
                      onClick={() => downLoadVisit("ios", source)}
                    />
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <Image
                    fluid={true}
                    src={dollarCard}
                    alt="dollar card"
                  />
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="row my-5">
              <div className="col-lg-6 my-auto">
                <h1>The Fastest Way to Transfer Money and Pay Online</h1>
                <p className={"mt-4"}>
                  Send money instantly across mobile networks and fund your
                  virtual dollar card for global spending. Enjoy secure,
                  convenient transactions—all in one app. Financial freedom is
                  just a few taps away!
                </p>
                <div className={"row pt-4"}>
                  <p>
                    <Image
                      className={"pointer"}
                      fluid={false}
                      src={playStore}
                      height={"auto"}
                      width={"150px"}
                      onClick={() => downLoadVisit("android", source)}
                    />
                    <span className={"mx-2"}></span>
                    <Image
                      className={"pointer"}
                      fluid={false}
                      src={appStore}
                      height={"auto"}
                      width={"150px"}
                      onClick={() => downLoadVisit("ios", source)}
                    />
                  </p>
                </div>
              </div>
              <div className="col-lg-6 py-5 my-5">
                <div>
                  <Image
                    fluid={true}
                    src={ventixWallet}
                    // width={500}
                    // height={500}
                    alt="ventix wallet"
                  />
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </Container>
    </div>
  )
}

export default Header
