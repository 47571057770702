import { useEffect, useState } from "react"

import NavBar from "../NavBar"
import Header from "./Header"
import ReactGA from "react-ga"
import HowItWorks from "./HowItWorks"
import Contact from "../Contact"
import Footer from "../Footer"
import Tutorials from "../Tutorials"
import WalletFAQs from "./WalletFAQs"

ReactGA.initialize("UA-161757367-7")

function Index() {
  const [source, setSource] = useState(null)

  useEffect(() => {
    getSource()
  }, [])

  const getSource = (vals) => {
    const urlParams = new URLSearchParams(window.location.search)
    const ref = urlParams.get("ref")

    ReactGA.event({
      action: "Page Visit",
      category: ref,
      label: ref + " visitor",
      value: 1,
    })
    setSource(ref || "None")
  }

  const tutorials = [
    {
      title: "Buy with Afrimoney",
      src: "https://www.canva.com/design/DAFHwyHDlK8/watch?embed",
    },
    {
      title: "Buy with Orange Money",
      src: "https://www.canva.com/design/DAFHxHy6qDs/watch?embed",
    },
    {
      title: "Claim a Private Ticket",
      src: "https://www.canva.com/design/DAFHxLSqkLE/watch?embed",
    },
  ]

  return (
    <div className="wallet bg-white">
      <NavBar mainApp={false} />
      <div>
        <Header source={source} />
        <HowItWorks />
        {/*<Tutorials tutorials={tutorials} />*/}
        <WalletFAQs />
        <Contact />
        <Footer />
      </div>
    </div>
  )
}

export default Index
