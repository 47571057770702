import React, { useState } from "react"
import Tabs from "../components/Tabs"
import NavBar from "./NavBar"
import { Container, Row, Col } from "react-bootstrap"

const Dashboard = () => {
  const items = [
    { key: "1", eventKey: "/", href: "#main", label: "Main" },
    {
      key: "2",
      eventKey: "/current-print",
      href: "#current-print",
      label: "Current Print",
    },
    {
      key: "3",
      eventKey: "/past-prints",
      href: "#past-prints",
      label: "Past Print",
    },
  ]

  const [activeKey, setActiveKey] = useState("/") // Set default active key

  const handleSelect = (selectedKey) => {
    setActiveKey(selectedKey)
  }

  const renderContent = (key) => {
    switch (key) {
      case "/":
        return (
          <section
            className="my-5 px-5 position-fixed"
            id="main"
          >
            <h2>Welcome</h2>
          </section>
        )
      case "/current-print":
        return (
          <section
            className="my-5 px-5 position-fixed"
            id="current-print"
          >
            <h2>Here is current</h2>
          </section>
        )
      case "/past-prints":
        return (
          <section
            className="my-5 px-5 position-fixed"
            id="past-prints"
          >
            <h2>Here is past</h2>
          </section>
        )
      default:
        return null
    }
  }

  return (
    <Container
      fluid
      className="my-3 px-5 justify-content-center"
      style={{ backgroundColor: "primary" }}
    >
      <NavBar />
      <Row style={{ height: "100vh" }}>
        <Col
          sm={12}
          md={12}
          style={{ padding: 5 }}
        >
          <Row className="px-sm-2 px-md-5">
            <Tabs
              items={items}
              variant="tabs"
              defaultActiveKey="/"
              handleSelect={handleSelect}
            />
          </Row>

          <Row className="px-sm-2 px-md-5">{renderContent(activeKey)}</Row>
        </Col>
      </Row>
    </Container>
  )
}

export default Dashboard
