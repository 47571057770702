import React from "react"
import { Row, Col, Card, Button } from "react-bootstrap" // Import Button from React Bootstrap
import ErrorMessage from "../components/ErrorMessage"
import { truncateText } from "../utils/functions"
import {
  FaCalendar,
  FaCalendarAlt,
  FaCalendarDay,
  FaShareAlt,
  FaShareAltSquare,
} from "react-icons/fa"
import ShareButtonModal from "../components/ShareButtonModal"
import ExpandableText from "../components/ExpandableText"
import { FaLocationPin } from "react-icons/fa6"

function EventCard({ event, openLink }) {
  const truncatedName = truncateText(event.name, 22)
  const truncatedDescription = truncateText(event.description, 30)
  const truncatedLocation = truncateText(event.full_address, 25)

  const [modalShow, setModalShow] = React.useState(false)

  const eventDetailsHandler = () => {
    openLink(`/event/${event.code}`)
  }

  const shareHandler = (event) => {
    event.preventDefault()
    setModalShow(true)
  }

  return (
    <Col
      sm={6}
      md={3}
      className="mb-4 text-justify-center"
    >
      <div className="event-card">
        <Card style={{ height: "40%", overflow: "hidden" }}>
          <div style={{ overflow: "hidden" }}>
            <Card.Img
              variant="top"
              src={event.image}
              alt={event.name}
              style={{
                objectFit: "cover",
                maxHeight: "200px",
                width: "100%",
                height: "100%",
              }}
              className={"pointer w-100"}
              onClick={eventDetailsHandler}
            />
          </div>

          <Card.Body
            className={"pointer"}
            onClick={eventDetailsHandler}
          >
            <h5 className="event-info mb-4 mt-2">{event.name}</h5>
            <p>
              <ExpandableText
                text={event.description}
                lines={2}
                showMore={false}
              />
            </p>
            <p className={"event-info mt-4"}>
              <FaCalendarDay
                className={"me-2 text-primary"}
                size={20}
              />
              <small>{event.start_datetime}</small>
            </p>
            <div className={" mt-4 d-flex align-middle"}>
              <div>
                <FaLocationPin
                  className={"my-auto me-2 text-primary"}
                  size={20}
                />
              </div>
              <div>
                <p className={"event-info"}>
                  <small>{event.full_address}</small>
                </p>
              </div>
            </div>
          </Card.Body>
          <Card.Footer className={"py-4"}>
            <div className="d-flex justify-content-end">
              <Button
                variant={"outline-primary"}
                className={""}
                onClick={shareHandler}
              >
                <FaShareAlt className={"me-2"} />
                Share
              </Button>
              <ShareButtonModal
                show={modalShow}
                eventLink={`https://getventix.com/event/${event.code}`}
                onHide={() => setModalShow(false)}
              />
            </div>
          </Card.Footer>
        </Card>
      </div>
    </Col>
  )
}

function EventList({ events, title, openLink }) {
  if (!events || events.length === 0) {
    return <ErrorMessage content={title} />
  }

  return (
    <Row className="justify-content w-100">
      {events.map((event, index) => (
        <EventCard
          event={event}
          key={index}
          openLink={openLink}
        />
      ))}
    </Row>
  )
}

export default EventList
