import React from "react"
import { Button } from "react-bootstrap"

const CustomButton = ({ label, onClick, variant, ...rest }) => {
  return (
    <div className="d-grid gap-2">
      <Button
        onClick={onClick}
        variant={variant || "primary"}
        {...rest}
      >
        {label}
      </Button>
    </div>
  )
}

export default CustomButton
