import { Card, Row, Col } from "react-bootstrap"

export default function EventCard({ icon, title, text }) {
  const Icon = icon
  return (
    <Card className="bg-transparent">
      <Card.Body>
        <Row>
          <Col
            xs={3}
            className="my-auto"
          >
            <Icon className="text-white icon-bkg-sm fs-1" />
          </Col>
          <Col
            xs={9}
            className="my-auto text-white"
          >
            <strong>{title}</strong>
            <br />
            <small>{text}</small>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}
