import { useEffect, useState } from "react"
// import './App.scss';
import NavBar from "./PageComponents/NavBar"
import Header from "./PageComponents/Header"
import "./index.css"
import ReactGA from "react-ga"
import HowItWorks from "./PageComponents/HowItWorks"
import EventSection from "./PageComponents/LandingPage/EventSection"
import EventTypes from "./PageComponents/EventTypes"
import Contact from "./PageComponents/Contact"
import Footer from "./PageComponents/Footer"
import Tutorials from "./PageComponents/Tutorials"
import UpcomingEventsContainer from "./PageComponents/UpcomingEventsContainer"
import { constants } from "./constants"
import Wallet from "./PageComponents/LandingPage/Wallet"
import Trivia from "./PageComponents/LandingPage/Trivia"
import Voting from "./PageComponents/LandingPage/Voting"

ReactGA.initialize("UA-161757367-7")

function App() {
  useEffect(() => {
    getSource()
  }, [])

  const [source, setSource] = useState(null)

  const getSource = (vals) => {
    const urlParams = new URLSearchParams(window.location.search)
    const ref = urlParams.get("ref")

    ReactGA.event({
      action: "Page Visit",
      category: ref,
      label: ref + " visitor",
      value: 1,
    })
    setSource(ref || "None")
  }

  return (
    <div className={"bg-white"}>
      <NavBar mainApp={true} />
      <div>
        <Header source={source} />
        <EventSection />
        <Wallet />
        <Voting />
        <Trivia source={source} />
        <Contact />
        <Footer />
      </div>
    </div>
  )
}

export default App
