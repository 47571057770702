import React from "react"
import { Container, Card } from "react-bootstrap"
import NavBar from "./NavBar"

const TermsOfService = () => {
  return (
    <div>
      <NavBar />
      <Container className="py-5 bg-transparent">
        <Card className={"p-5"}>
          <Card.Title className={""}>
            <h1 className="mb-4">Terms of Service</h1>
            <p>
              <strong>Effective Date: August 15, 2024</strong>
            </p>
          </Card.Title>
          <Card.Body>
            <h2 className="mt-5">1. Introduction</h2>
            <p>
              Welcome to Ventix LLC (“Ventix,” “we,” “us,” or “our”). By using
              our services, including our website, mobile applications, and any
              other services provided by Ventix (collectively, the “Services”),
              you agree to comply with and be bound by the following Terms of
              Service (“ToS”). These terms govern your access to and use of all
              Ventix Services, including but not limited to event ticketing,
              voting, trivia, and the Ventix Wallet (cash account, cash card,
              virtual USD card). If you do not agree to these terms, please do
              not use our Services.
            </p>
            <p>
              Ventix LLC is a registered business in Sierra Leone and is a
              subsidiary of Lexury Group LLC, a registered business in the USA
              in Delaware.
            </p>

            <h2 className="mt-5">2. Eligibility</h2>
            <p>
              To use our Services, you must be at least 18 years old or have the
              consent of a parent or guardian. By using our Services, you
              represent and warrant that you meet this eligibility requirement.
            </p>

            <h2 className="mt-5">3. Account Registration</h2>
            <p>
              To access certain features of the Services, you may be required to
              create an account. You agree to provide accurate, current, and
              complete information during the registration process and to update
              such information as necessary. You are responsible for
              safeguarding your account credentials and for any activities or
              actions under your account. Ventix is not liable for any loss or
              damage arising from your failure to protect your account
              credentials.
            </p>

            <h2 className="mt-5">4. Services Overview</h2>

            <h3 className="mt-4">4.1 Event Ticketing</h3>
            <p>
              Ventix provides a platform for purchasing and managing event
              tickets. Event organizers can create and sell tickets for their
              events through the Ventix platform. Attendees can purchase
              tickets, which are stored electronically within the Ventix app.
            </p>
            <ul>
              <li>
                <strong>Ticket Purchase:</strong>
                <br />
                Tickets can be purchased using various payment methods,
                including mobile money services, credit/debit cards, or funds
                from your Ventix Wallet. All ticket purchases are final and
                non-refundable unless otherwise specified by the event
                organizer.
              </li>
              <li>
                <strong>Ticket Use:</strong>
                <br />
                Each ticket contains a unique QR code or ticket number, which
                must be presented at the event entrance. Ventix is not
                responsible for any issues arising from lost or stolen tickets.
              </li>
              <li>
                <strong>Event Cancellation:</strong>
                <br />
                In the event of cancellation, the event organizer is responsible
                for issuing refunds in accordance with their refund policy.
              </li>
            </ul>

            <h3 className="mt-4">4.2 Voting Platform</h3>
            <p>
              Ventix provides a platform for users to participate in voting
              events, including but not limited to talent shows, contests, and
              other competitions.
            </p>
            <ul>
              <li>
                <strong>Vote Purchase:</strong>
                <br />
                Votes can be purchased using mobile money services, credit/debit
                cards, or funds from your Ventix Wallet. Votes are
                non-refundable and non-transferable.
              </li>
              <li>
                <strong>Voting Process:</strong>
                <br />
                Users can distribute their purchased votes among multiple
                contestants. Voting results may be displayed in real-time,
                depending on the organizer’s settings. Ventix is not responsible
                for the outcome of any voting events.
              </li>
              <li>
                <strong>Voting Integrity:</strong>
                <br />
                Ventix employs security measures to ensure the integrity of the
                voting process. Any attempt to manipulate or interfere with the
                voting process is strictly prohibited and may result in account
                suspension or termination.
              </li>
            </ul>

            <h3 className="mt-4">4.3 Trivia</h3>
            <p>
              Ventix offers trivia games as part of its Services. Users can
              participate in trivia games to test their knowledge and win
              rewards.
            </p>
            <ul>
              <li>
                <strong>Participation:</strong>
                <br />
                To participate in trivia games, users must have a Ventix
                account. Some trivia games may require the purchase of entry
                tickets or credits.
              </li>
              <li>
                <strong>Prizes:</strong>
                <br />
                Prizes for trivia games vary and are determined by Ventix or the
                event organizer. Prizes are non-transferable and may be subject
                to additional terms and conditions.
              </li>
              <li>
                <strong>Fair Play:</strong>
                <br />
                Users are expected to participate in trivia games fairly. Any
                form of cheating or manipulation is strictly prohibited and may
                result in account suspension or disqualification from the game.
              </li>
            </ul>

            <h3 className="mt-4">4.4 Ventix Wallet</h3>
            <p>
              The Ventix Wallet is a financial tool that allows users to store
              and manage funds. It includes a cash account, a contactless cash
              card, and a virtual USD card.
            </p>
            <ul>
              <li>
                <strong>Cash Account:</strong>
                <br />
                Users can deposit money into their Ventix Cash Account using
                mobile money services or other supported payment methods. The
                cash account can be used to make purchases within the Ventix
                platform or send money to other users.
              </li>
              <li>
                <strong>Contactless Cash Card:</strong>
                <br />
                The Ventix Contactless Cash Card is linked to your Cash Account
                and allows you to make secure payments within the Ventix
                ecosystem. The card must be activated and protected with a
                unique PIN.
              </li>
              <li>
                <strong>Virtual USD Card:</strong>
                <br />
                The Virtual USD Card allows users to make online purchases in
                USD. Funds can be transferred from the Cash Account to the
                Virtual USD Card. The card must be set up with valid personal
                identification information and is protected by a security PIN.
              </li>
              <li>
                <strong>Security:</strong>
                <br />
                All transactions made through the Ventix Wallet are protected by
                PIN-based security protocols. Users are responsible for
                safeguarding their PIN and other account information.
              </li>
              <li>
                <strong>Account Balance:</strong>
                <br />
                Users are responsible for monitoring their account balance.
                Ventix is not liable for any unauthorized transactions resulting
                from the misuse of account credentials.
              </li>
            </ul>

            <h2 className="mt-5">5. Payment and Fees</h2>
            <p>
              Certain aspects of the Services may require payment. By using our
              paid Services, you agree to pay all applicable fees and charges.
              Ventix reserves the right to change its fees at any time, but we
              will notify you in advance of any changes.
            </p>

            <h2 className="mt-5">6. User Conduct</h2>
            <p>By using our Services, you agree to:</p>
            <ul>
              <li>
                <strong>Respect Others:</strong>
                <br />
                Do not engage in any activity that is harmful, abusive, or
                disruptive to other users or the Services.
              </li>
              <li>
                <strong>Comply with Laws:</strong>
                <br />
                Ensure that your use of the Services complies with all
                applicable laws and regulations.
              </li>
              <li>
                <strong>Protect Your Account:</strong>
                <br />
                Do not share your account information with others or allow
                others to access your account.
              </li>
            </ul>

            <h2 className="mt-5">7. Intellectual Property</h2>
            <p>
              All content, trademarks, and other intellectual property
              associated with the Services are the property of Ventix or its
              licensors. You may not use, reproduce, or distribute any content
              from the Services without our express permission.
            </p>

            <h2 className="mt-5">8. Termination</h2>
            <p>
              Ventix reserves the right to suspend or terminate your access to
              the Services at any time for any reason, including but not limited
              to a violation of these ToS. Upon termination, all rights granted
              to you under these ToS will cease immediately.
            </p>

            <h2 className="mt-5">9. Disclaimers</h2>
            <p>
              The Services are provided “as is” and “as available” without any
              warranties of any kind, either express or implied. Ventix does not
              warrant that the Services will be uninterrupted or error-free.
            </p>

            <h2 className="mt-5">10. Limitation of Liability</h2>
            <p>
              To the fullest extent permitted by law, Ventix shall not be liable
              for any indirect, incidental, special, consequential, or punitive
              damages arising out of or related to your use of the Services.
            </p>

            <h2 className="mt-5">11. Governing Law</h2>
            <p>
              These ToS are governed by and construed in accordance with the
              laws of Sierra Leone. Any disputes arising out of or related to
              these ToS or the Services shall be resolved in the courts of
              Sierra Leone.
            </p>

            <h2 className="mt-5">12. Changes to the Terms of Service</h2>
            <p>
              We may update these ToS from time to time to reflect changes in
              our practices, technology, legal requirements, or other factors.
              When we make changes, we will update the “Effective Date” at the
              top of this document. We encourage you to review these ToS
              periodically to stay informed about our terms and conditions.
            </p>

            <h2 className="mt-5">13. Contact Us</h2>
            <p>
              If you have any questions or concerns about these Terms of
              Service, please contact us at:
            </p>
            <p>
              Ventix LLC
              <br />
              42E Water Street, Congo Cross
              <br />
              Freetown, Sierra Leone
              <br />
              Email: <a href="mailto:info@getventix.com">info@getventix.com</a>
              <br />
              Phone: +232 80 778877 | +232 73 778877
            </p>
          </Card.Body>
        </Card>
      </Container>
    </div>
  )
}

export default TermsOfService
