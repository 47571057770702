import React from "react"
import { Container, Row, Col, Card } from "react-bootstrap"
import NavBar from "./NavBar"

const PrivacyPolicy = () => {
  return (
    <div>
      <NavBar />
      <Container className="py-5 bg-transparent">
        <Card className={"p-5"}>
          <Card.Title className={""}>
            <h1 className="mb-4">Privacy Policy</h1>
            <p>
              <strong>Effective Date: August 15, 2024</strong>
            </p>
          </Card.Title>
          <Card.Body>
            <h2 className="mt-5">1. Introduction</h2>
            <p>
              Welcome to Ventix! Ventix LLC (“Ventix,” “we,” “us,” or “our”) is
              committed to protecting your privacy and ensuring that your
              personal information is handled in a safe and responsible manner.
              This Privacy Policy outlines how we collect, use, share, and
              protect your information when you use our platform, including our
              website, mobile applications, and any other services provided by
              Ventix (collectively, the “Services”).
            </p>
            <p>
              Ventix LLC is a registered business in Sierra Leone and is a
              subsidiary of Lexury Group LLC, a registered business in the USA
              in Delaware.
            </p>
            <p>
              By using our Services, you agree to the collection and use of
              information in accordance with this Privacy Policy.
            </p>

            <h2 className="mt-5">2. Information We Collect</h2>
            <p>We may collect the following types of information:</p>
            <ul>
              <li>
                <strong>Personal Information</strong>
                <br />
                When you register for an account, purchase tickets, participate
                in voting, or use other features of our Services, we may collect
                personal information such as your name, email address, phone
                number, date of birth, payment information, and any other
                information you choose to provide.
              </li>
              <li>
                <strong>Technical Information</strong>
                <br />
                We collect information about your device, including IP address,
                browser type, operating system, and other technical data that
                helps us provide and maintain our Services.
              </li>
              <li>
                <strong>Usage Information</strong>
                <br />
                We collect information about your interactions with our
                platform, including the pages you visit, the actions you take,
                and the content you view.
              </li>
              <li>
                <strong>Location Information</strong>
                <br />
                With your permission, we may collect information about your
                location to provide location-based services.
              </li>
            </ul>

            <h2 className="mt-5">3. How We Use Your Information</h2>
            <p>
              We may use the information we collect for the following purposes:
            </p>
            <ul>
              <li>
                <strong>To Provide and Improve Our Services</strong>
                <br />
                We use your information to deliver and improve our Services,
                process transactions, manage accounts, and provide customer
                support.
              </li>
              <li>
                <strong>To Communicate with You</strong>
                <br />
                We may use your information to send you important updates,
                notifications, and promotional content related to our Services.
              </li>
              <li>
                <strong>To Ensure Security</strong>
                <br />
                We use your information to maintain the security of our
                platform, detect and prevent fraud, and ensure compliance with
                legal obligations.
              </li>
              <li>
                <strong>To Personalize Your Experience</strong>
                <br />
                We may use your information to tailor your experience on our
                platform, including providing personalized content and
                recommendations.
              </li>
              <li>
                <strong>For Research and Development</strong>
                <br />
                We may use aggregated or anonymized information for research,
                analysis, and product development to improve our Services.
              </li>
            </ul>

            <h2 className="mt-5">4. Sharing Your Information</h2>
            <p>
              We do not sell your personal information. However, we may share
              your information in the following circumstances:
            </p>
            <ul>
              <li>
                <strong>With Service Providers</strong>
                <br />
                We may share your information with third-party service providers
                who help us operate our platform, process payments, and provide
                other essential services.
              </li>
              <li>
                <strong>With Legal Authorities</strong>
                <br />
                We may disclose your information to law enforcement or
                regulatory authorities if required by law or to protect our
                rights, property, or the safety of others.
              </li>
              <li>
                <strong>With Affiliates</strong>
                <br />
                We may share your information with our affiliates and
                subsidiaries for business purposes consistent with this Privacy
                Policy.
              </li>
            </ul>

            <h2 className="mt-5">5. Data Storage and Security</h2>
            <p>
              Your information is stored on servers located in the United
              States. We implement industry-standard security measures to
              protect your data from unauthorized access, loss, or misuse.
              However, no data transmission over the internet or storage system
              can be guaranteed to be 100% secure, and we cannot ensure the
              absolute security of your information.
            </p>

            <h2 className="mt-5">6. Your Rights and Choices</h2>
            <ul id={"data-deletion"}>
              <li>
                <strong>Access and Update Your Information</strong>
                <br />
                You have the right to access and update the personal information
                you provide to us. You can do so by logging into your account or
                contacting us at{" "}
                <a href="mailto:info@getventix.com">info@getventix.com</a>.
              </li>
              <li>
                <strong>Request Data Deletion</strong>
                <br />
                You can request the deletion of your personal data by emailing
                us at
                <a href="mailto:data@getventix.com">data@getventix.com</a>. Upon
                request, we will delete your personal information, including
                your name, email address, phone number, and any other associated
                account details. Please note that we may retain certain
                information, such as transaction history or data required by
                law, for legitimate business purposes or legal compliance.
              </li>

              <li>
                <strong>Opt-Out of Communications</strong>
                <br />
                You can opt-out of receiving promotional emails and other
                communications from us by following the unsubscribe instructions
                provided in those emails.
              </li>
            </ul>

            <h2 className="mt-5">7. Licensing and Compliance</h2>
            <p>
              Ventix LLC is a registered business in Sierra Leone and is
              licensed by the Bank of Sierra Leone to provide financial
              services. We adhere to the regulations and standards set forth by
              the Bank of Sierra Leone, ensuring that our financial operations
              are conducted in a secure, transparent, and compliant manner.
            </p>

            <h2 className="mt-5">8. International Data Transfers</h2>
            <p>
              As a global platform, your information may be transferred to and
              processed in countries other than your own, including the United
              States, where our servers are located. By using our Services, you
              consent to the transfer of your information to the United States
              and other countries where our service providers operate.
            </p>

            <h2 className="mt-5">9. Children’s Privacy</h2>
            <p>
              Our Services are not directed at children under the age of 13, and
              we do not knowingly collect personal information from children. If
              we become aware that we have collected personal information from a
              child under the age of 13, we will take steps to delete that
              information as soon as possible.
            </p>

            <h2 className="mt-5">10. Changes to This Privacy Policy</h2>
            <p>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices, technology, legal requirements, or other
              factors. When we make changes, we will update the “Effective Date”
              at the top of this policy. We encourage you to review this policy
              periodically to stay informed about how we are protecting your
              information.
            </p>

            <h2 className="mt-5">11. Contact Us</h2>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us at:
            </p>
            <p>
              Ventix LLC
              <br />
              42E Water Street, Congo Cross
              <br />
              Freetown, Sierra Leone
              <br />
              Email: <a href="mailto:info@getventix.com">info@getventix.com</a>
              <br />
              Phone: +232 80 778877 | +232 73 778877
            </p>
            <p>
              We are committed to addressing your concerns and providing you
              with the information you need to feel confident about how your
              data is handled.
            </p>
          </Card.Body>
        </Card>
      </Container>
    </div>
  )
}

export default PrivacyPolicy
