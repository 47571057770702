import React from "react"
import { Card, Col, Row } from "react-bootstrap"

function VotingPeriodListItem({ period, onClick, clickable = true }) {
  if (!period.id) {
    return <div />
  }
  return (
    <Card
      body={true}
      className={`border-1 rounded-3 border-primary ${
        clickable ? "pointer" : ""
      }`}
      onClick={() => {
        if (!clickable) return
        if (onClick) {
          onClick(period)
          return
        }
        window.open(`/voting/${period.id}`, "_blank")
      }}
    >
      <Row className={"align-middle gap-0"}>
        <Col
          xs={3}
          md={2}
          className={"my-auto text-center gap-0"}
        >
          <h6>{period.start[0]}</h6>
          <h3>{period.start[1]}</h3>
          <h6>{period.start[2]}</h6>
          <small>{period.start[3]}</small>
        </Col>
        <Col
          xs={6}
          md={8}
          className={"my-auto text-center gap-0"}
        >
          <div>
            <strong>{period.name}</strong>
            <br />
            <small>{period.description}</small>
          </div>
        </Col>
        <Col
          xs={3}
          md={2}
          className={"my-auto text-center gap-0"}
        >
          <h6>{period.end[0]}</h6>
          <h3>{period.end[1]}</h3>
          <h6>{period.end[2]}</h6>
          <small>{period.end[3]}</small>
        </Col>
      </Row>
    </Card>
  )
}

export default VotingPeriodListItem
