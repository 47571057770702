import React, { useState } from "react"
import { Form } from "react-bootstrap"
import Input from "../forms/CustomInputField"
import Checkbox from "../forms/CheckBox"
import CustomButton from "../forms/CustomButton"

const LoginForm = ({ handleSubmit }) => {
  const [formState, setFormState] = useState({
    username: "",
    password: "",
    rememberMe: false,
  })

  const handleChange = (e) => {
    const { name, value, checked } = e.target
    setFormState((prevState) => ({
      ...prevState,
      [name]: name === "rememberMe" ? checked : value,
    }))
  }

  const handleFormSubmit = (e) => {
    e.preventDefault() // Prevent the default form submission behavior
    handleSubmit(formState)
  }

  return (
    <>
      <Form onSubmit={handleFormSubmit}>
        <Input
          type="text"
          label="Email"
          className="mb-2"
          placeholder="Enter your email address"
          value={formState.username || ""}
          onChange={handleChange}
          name="username"
        />
        <Input
          type="password"
          label="Password"
          className="mb-2"
          placeholder="Enter your password"
          value={formState.password || ""}
          name="password"
          onChange={handleChange}
        />
        <Checkbox
          label="Remember for 30 days"
          className="mt-4"
          name="rememberMe"
          checked={formState.rememberMe}
          onChange={handleChange}
        />
        <CustomButton
          label="Login"
          className="text-white mt-4"
          size="md"
          type="submit"
          variant="primary"
        />
      </Form>
    </>
  )
}

export default LoginForm
