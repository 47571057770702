import React from "react"
import { Form } from "react-bootstrap"

const Checkbox = ({ label, checked, onChange, ...rest }) => {
  return (
    <Form.Group controlId="formBasicCheckbox">
      <Form.Check
        type="checkbox"
        label={label}
        checked={checked}
        onChange={onChange}
        {...rest}
      />
    </Form.Group>
  )
}

export default Checkbox
