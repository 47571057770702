import { Container, Image, Row, Col, Button } from "react-bootstrap"
import votingScreen from "../../assets/images/voting-screen.png"
import { ListGroup, ListGroupItem } from "react-bootstrap"
import { Fade } from "react-awesome-reveal"
import { FaCircleCheck } from "react-icons/fa6"
import {
  FaMobileAlt,
  FaGlobe,
  FaShieldAlt,
  FaClock,
  FaDownload,
} from "react-icons/fa"
import { IoMdCheckmarkCircleOutline } from "react-icons/io"

export default function Voting() {
  const benefits = [
    {
      icon: FaMobileAlt,
      text: "Vote anytime, anywhere with our seamless mobile app.",
    },
    {
      icon: FaGlobe,
      text: "Access voting from any device across the globe.",
    },
    {
      icon: FaShieldAlt,
      text: "Enjoy secure, confidential voting with robust protection.",
    },
    {
      icon: FaClock,
      text: "Vote at your convenience with 24/7 availability.",
    },
    {
      icon: FaDownload,
      text: "Get started quickly by downloading our easy-to-use app.",
    },
  ]

  return (
    <div
      className={"voting-bkg min-vh-75 py-5"}
      id={"voting"}
    >
      <Container className={"pt-5 text-white"}>
        <Fade>
          <Row>
            <Col lg={6}>
              <Image
                src={votingScreen}
                alt="phone image"
                className="img-fluid mt-5"
                fluid={true}
              />
            </Col>
            <Col
              lg={6}
              className={""}
            >
              <h1 className="text-x fs-1 mb-4"> Voting Solutions!</h1>
              <p className="text-md align-items-center mt-4 fs-5">
                Embrace the power of democracy with our cutting-edge mobile app
                and web-based voting platforms. Whether you're a voter, or an
                organizer, our innovative solutions are tailored to streamline
                the voting process and ensure transparency, accessibility, and
                security at every step.
              </p>

              <ListGroup className={"mt-4"}>
                {benefits.map((benefit, index) => (
                  <ListGroupItem
                    className="bg-transparent border-0 d-flex justify-content-start"
                    key={index}
                  >
                    <div className={"me-4"}>
                      <benefit.icon
                        className="text-white"
                        size={25}
                      />
                    </div>
                    <div className={"text-white align-middle"}>
                      <p>{benefit.text}</p>
                    </div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Col>
          </Row>
          <Row className="pb-5 mt-5 pt-5">
            <Col className="d-flex justify-content-center align-items-center">
              <Button
                className="learn-btn btn-lg text-black bg-white"
                size={"lg"}
                href={"/events/Voting"}
              >
                View Voting Events
              </Button>
            </Col>
          </Row>
        </Fade>
      </Container>
    </div>
  )
}
