import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { LoadingView } from "../utils/components"
import { apiRequest } from "../requests"
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Navbar,
  Row,
} from "react-bootstrap"
import NavBar from "./NavBar"
import { Helmet } from "react-helmet"
import VotingPeriodListItem from "./components/Event/VotingPeriodListItem"
import ContestantListItem from "./components/Event/ContestantListItem"
import OrderInfoRetrivalForm from "./forms/Events/OrderInfoRetrivalForm"
import AppModal from "../components/AppModal"
import { constants } from "../constants"
import { isEmpty, updateObjectState } from "../utils/functions"
import { FaSearch } from "react-icons/fa"

function VotingPeriodPage() {
  useEffect(() => {
    getPeriod()
  }, [])

  const [orderInfo, setOrderInfo] = useState({
    number: null,
    emailOrPhone: null,
    amount: null,
    votes: 0,
    requested: false,
  })
  const [data, setData] = useState({
    votes_count: 1,
  })
  const [periodFilterTerm, setPeriodFilterTerm] = useState("")
  const [filterTerm, setFilterTerm] = useState("")
  const [modal, setModal] = useState({})
  const [showVoteModal, setShowVoteModal] = useState(false)
  const [showPeriodChangeModal, setShowPeriodChangeModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [period, setPeriod] = useState({
    contestants: [],
  })
  const [otherPeriods, setOtherPeriods] = useState([])
  const [contestant, setContestant] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const { periodId } = useParams()

  // get orderId from the url search params
  const searchParams = new URLSearchParams(window.location.search)
  const orderId = searchParams.get("orderId")

  const getPeriod = async (otherPeriodId) => {
    if (otherPeriodId && otherPeriodId === period.id) {
      setShowPeriodChangeModal(false)
      return
    }
    setIsLoading(true)
    const { success, response } = await apiRequest.get(
      `api/event/voting/period/${otherPeriodId || periodId}`
    )
    if (success) {
      setPeriod({
        ...response.period,
        contestants: response.contestants,
      })
      setOtherPeriods([response.period, ...response.other_periods])
    } else {
      setModal({
        title: "Error",
        body: response.message || constants.unknownErrorMsg,
      })
      setShowModal(true)
    }
    setIsLoading(false)
    if (otherPeriodId) {
      setShowPeriodChangeModal(false)
    }
  }

  const submitOrderInfo = async () => {
    setIsLoading(true)
    const { success, response } = await apiRequest.post(
      `api/event/voting/period/${period.id}/order-info${
        orderId ? `?orderId=${orderId}` : ""
      }`,
      orderInfo
    )
    if (success) {
      setOrderInfo(response.order_info)
    } else {
      setModal({
        title: "Error",
        body: response.message || constants.unknownErrorMsg,
      })
      setShowModal(true)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (!period.id || !orderId || orderInfo.requested) return
    submitOrderInfo()
    setShowPeriodChangeModal(true)
  }, [period.id])

  const submitVote = async () => {
    setIsLoading(true)
    setShowVoteModal(false)
    const { success, response } = await apiRequest.post(
      `api/event/voting/period/${period.id}/vote/${contestant.id}`,
      {
        votes_count: data.votes_count,
        order_info: orderInfo,
      }
    )
    let title = "Error"
    if (success) {
      title = "Vote Successful"
      setOrderInfo(response.order_info)
      setData({ votes_count: 1 })
    }
    setModal({
      title: title,
      body: response.message || constants.unknownErrorMsg,
    })
    setShowModal(true)
    setIsLoading(false)
  }

  return (
    <div className={"bg-secondary bg-opacity-25"}>
      <NavBar />
      <LoadingView
        isLoading={isLoading}
        view={
          <div>
            {period?.id && (
              <Helmet>
                <title>{`Vote - ${period.name}`}</title>
                <meta
                  property="og:title"
                  content={period.name}
                />
                <meta
                  property="og:description"
                  content={period.description}
                />
                <meta
                  property="og:url"
                  content={"https://getventix.com/voting/" + period.id}
                />
              </Helmet>
            )}

            <div className={"container my-3"}>
              <VotingPeriodListItem
                period={period}
                clickable={false}
              />
              <div className={"d-flex justify-content-end mt-4 mb-2"}>
                <Button
                  variant={"primary"}
                  onClick={() => {
                    setShowPeriodChangeModal(true)
                  }}
                >
                  Change Category / Period
                </Button>
              </div>

              <Row className={"mt-3"}>
                <Col md={4}>
                  <Card className={"sticky-lg-top"}>
                    <Card.Header>
                      <Card.Title
                        as={"h3"}
                        className={"my-4 text-center"}
                      >
                        Your Available Votes
                      </Card.Title>
                      {!orderId && (
                        <Card.Subtitle
                          as={"p"}
                          className={"mt-3"}
                        >
                          Enter your order number, email / phone number and
                          order amount from your order confirmation to retrieve
                          your votes
                        </Card.Subtitle>
                      )}
                      <Card.Body className={"mt-3"}>
                        {orderInfo.requested && (
                          <div className={"text-center"}>
                            <h4 className={"text-primary"}>
                              Order # <br />
                              VTX-{orderInfo.number}
                            </h4>
                            <p className={"text-muted"}>{orderInfo.contact}</p>

                            <h1 className={"text-center"}>{orderInfo.votes}</h1>
                            <p className={"mb-4"}>Votes Available</p>

                            <p>
                              Click on the "Vote for" button on a contestants
                              card to vote for them
                            </p>
                          </div>
                        )}
                        {!orderInfo.requested && (
                          <div>
                            <OrderInfoRetrivalForm
                              orderInfo={orderInfo}
                              setOrderInfo={setOrderInfo}
                              onSubmit={() => {
                                submitOrderInfo()
                              }}
                            />
                          </div>
                        )}
                      </Card.Body>
                    </Card.Header>
                  </Card>
                </Col>
                <Col md={8}>
                  <div>
                    <div className={"my-3"}>
                      <InputGroup>
                        <InputGroup.Text>
                          <FaSearch />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder="Search Contestants"
                          value={filterTerm}
                          onChange={(e) => {
                            setFilterTerm(e.target.value)
                          }}
                        />
                      </InputGroup>
                    </div>
                    {period?.contestants?.map((contestant, index) => {
                      if (
                        !isEmpty(filterTerm) &&
                        !contestant.title
                          .toLowerCase()
                          .includes(filterTerm.toLowerCase())
                      )
                        return <div />
                      return (
                        <div
                          key={contestant.id}
                          className={"mb-3"}
                        >
                          <ContestantListItem
                            contestant={contestant}
                            index={index}
                            period={period}
                            availableVotes={orderInfo.votes}
                            voteCallback={(contestant) => {
                              setContestant(contestant)
                              setShowVoteModal(true)
                            }}
                          />
                        </div>
                      )
                    })}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        }
      />

      <AppModal
        modal={modal}
        setShowModal={setShowModal}
        showModal={showModal}
      />

      <Modal
        show={showVoteModal}
        onHide={() => {
          setShowVoteModal(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Vote for ${contestant.title}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className={"text-center mb-4"}>
              <h1>{orderInfo.votes - data.votes_count}</h1>
              <p className={"mb-4"}>Available Votes</p>

              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                }}
              >
                <InputGroup>
                  <Button
                    type={"button"}
                    variant="outline-primary"
                    disabled={data.votes_count === 1}
                    onClick={() => {
                      if (data.votes_count === 1) return
                      const newQty = data.votes_count - 1
                      updateObjectState(setData, {
                        votes_count: newQty,
                      })
                    }}
                  >
                    -1 Vote
                  </Button>
                  <Form.Control
                    readOnly={true}
                    className={"text-center fs-3"}
                    placeholder="Votes"
                    value={data.votes_count}
                  />
                  <Button
                    type={"button"}
                    className={"text-white"}
                    variant="primary"
                    onClick={() => {
                      const newCount = data.votes_count + 1
                      if (newCount > orderInfo.votes) return

                      updateObjectState(setData, {
                        votes_count: newCount,
                      })
                    }}
                  >
                    +1 Vote
                  </Button>
                </InputGroup>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={"py-4"}>
          <Button
            className={"w-100"}
            variant="primary"
            onClick={() => {
              submitVote()
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showPeriodChangeModal}
        onHide={() => {
          setShowPeriodChangeModal(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose Category / Period</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className={"text-center mb-4"}>
              <p className={"mb-4"}>Choose a category / period to vote in</p>
            </div>
          </div>

          <div className={"my-4"}>
            <InputGroup>
              <InputGroup.Text>
                <FaSearch />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search Voting Categories / Periods"
                value={periodFilterTerm}
                onChange={(e) => {
                  setPeriodFilterTerm(e.target.value)
                }}
              />
            </InputGroup>
          </div>

          {otherPeriods.map((period, index) => {
            if (
              !isEmpty(periodFilterTerm) &&
              !period.name
                .toLowerCase()
                .includes(periodFilterTerm.toLowerCase())
            )
              return <div />
            return (
              <div
                key={index}
                className={"mb-3"}
              >
                <VotingPeriodListItem
                  period={period}
                  clickable={true}
                  onClick={(p) => {
                    getPeriod(p.id)
                  }}
                />
              </div>
            )
          })}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default VotingPeriodPage
