import React from "react"
import { Container, Row, Col, Card } from "react-bootstrap"
import longinImage from "../assets/images/headerBkg.jpg"
import NavBar from "./NavBar"
import LoginForm from "./forms/LoginForm"
import { useHistory } from "react-router-dom"

const LoginPage = () => {
  const history = useHistory()

  const handleSubmit = ({ username, password, rememberMe }) => {
    // Handle form submission, e.g., authentication logic
    const creden = {
      username,
      password,
      rememberMe,
    }
    console.log("check", creden)
    if (username === "user@ventix.com" && password === "p@ssword246") {
      history.replace("/dashboard")
      window.location.reload()
    } else {
      alert("Invalid credentials. Please try again.")
    }
  }

  return (
    <Container
      fluid
      style={{ backgroundColor: "#fff" }}
    >
      <NavBar />
      <Row style={{ height: "100vh" }}>
        <Col
          sm={4}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Card style={{ margin: "0 auto", border: "none" }}>
            <Card.Body>
              <Card.Title className="display-6">Welcome back!</Card.Title>
              <h6 className="mb-4">
                Enter your credentials to access your account
              </h6>
              <LoginForm handleSubmit={handleSubmit} />
            </Card.Body>
          </Card>
        </Col>
        <Col
          sm={8}
          style={{ padding: 0 }}
        >
          <img
            src={longinImage}
            alt="Login Image"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default LoginPage
