import React, { useState } from "react"
import { Container, Nav } from "react-bootstrap"

function Tabs({ items, variant, defaultActiveKey, handleSelect }) {
  const [activeKey, setActiveKey] = useState(defaultActiveKey)

  const handleTabSelect = (selectedKey) => {
    setActiveKey(selectedKey)
    handleSelect(selectedKey) // Call the parent's handleSelect function
  }

  return (
    <Container className="px-2 px-md-5">
      <Nav
        fill
        variant={variant}
        defaultActiveKey={defaultActiveKey}
        className="position-fixed justify-content-center"
        expand="md"
        onSelect={handleTabSelect}
      >
        {items.map((item) => (
          <Nav.Item key={item.key}>
            <Nav.Link
              eventKey={item.eventKey}
              href={item.href}
              disabled={item.disabled}
              active={activeKey === item.eventKey}
            >
              {item.label}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </Container>
  )
}

export default Tabs
