import React from "react"

const LearnMoreButton = ({ to, children }) => {
  return (
    <a
      href={to}
      className="btn learn-btn btn-lg text-primary bg-white"
    >
      {children}
    </a>
  )
}

export default LearnMoreButton
