import { Container, Image, Row, Col } from "react-bootstrap"
import appStore from "../../assets/images/app-store.png"
import playStore from "../../assets/images/play-store.png"
import trivalVector from "../../assets/images/trivalVector.png"
import trival from "../../assets/images/trivia-img.png"
import { Fade } from "react-awesome-reveal"

import { downLoadVisit } from "../../analytics"

export default function Trivia({ source }) {
  return (
    <div
      className={"trival-bkg min-vh-75 py-5"}
      id={"trivia"}
    >
      <Container
        className={
          "pt-5 mr-4 text-white d-flex flex-column align-items-center justify-content-center"
        }
      >
        <Fade>
          <Row>
            <Col className="align-self-center">
              <h1 className="text-center pb-5">
                Discover knowledge with Trivia Space!
              </h1>
            </Col>
            <div>
              <Image
                className="align-items-right"
                fluid={true}
                src={trivalVector}
                width={50}
                height={50}
                alt="trival image"
              />
            </div>
          </Row>

          <Row>
            <Col lg={6}>
              <div>
                <Image
                  fluid={true}
                  src={trival}
                  alt="trival image"
                />
              </div>
            </Col>
            <Col
              lg={6}
              className="pt-2 d-flex flex-column align-items-center justify-content-top"
            >
              <p className="text-md align-items-center justify-content-center fs-5">
                Embark on a captivating journey of discovery with Ventix Trivia,
                the ultimate trivia that unlocks a world of knowledge with every
                question you answer. Join a vibrant community of eager learners
                and trivia enthusiasts as you embark on an adventure through
                fascinating facts, mind-bending questions, and rewarding
                challenges.
              </p>
              <Row className="mt-3">
                <h3 className="text-md align-items-end mt-4">
                  Why Ventix Trivia ?
                </h3>
                <p className="text-md align-items-end mt-3 fs-5">
                  Ventix trivia isn't just a questions and answers. It's a
                  gateway to a world of endless discovery and intellectual
                  enrichment. With our user-friendly interface, engaging
                  gameplay, and rewarding experiences, we're committed to making
                  learning fun and accessible for everyone.
                </p>
              </Row>
            </Col>
          </Row>
          {/*<Row className="py-5">*/}
          {/*  <Col className="d-flex justify-content-center align-items-center">*/}
          {/*    <button*/}
          {/*      className="btn btn-lg learn-btn text-black bg-white"*/}
          {/*      size="lg"*/}
          {/*    >*/}
          {/*      Learn More*/}
          {/*    </button>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </Fade>
      </Container>
    </div>
  )
}
