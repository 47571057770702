import { Container, Nav, Navbar } from "react-bootstrap"
import logo from "../assets/images/logoTransColor512.png"

function NavBar({ mainApp }) {
  return (
    <>
      <Navbar
        bg="light"
        expand="lg"
        className={"fixed-top bg-white"}
      >
        <Container className={"px-3"}>
          <Navbar.Brand href="/">
            <img
              alt="Ventix Logo"
              src={logo}
              width="100"
              height="auto"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav.Link
              className={"text-primary"}
              href={mainApp ? "#home" : "/"}
            >
              Home
            </Nav.Link>
            {mainApp && (
              <Nav>
                <Nav.Link
                  className={"text-primary"}
                  href={"#events"}
                >
                  Events
                </Nav.Link>
                <Nav.Link
                  className={"text-primary"}
                  href={"#wallet"}
                >
                  Wallet
                </Nav.Link>
                <Nav.Link
                  className={"text-primary"}
                  href={"#voting"}
                >
                  Voting
                </Nav.Link>

                <Nav.Link
                  className={"text-primary"}
                  href={"#trivia"}
                >
                  Trivia
                </Nav.Link>

                <Nav.Link
                  className={"text-primary"}
                  href={"#contact"}
                >
                  Contact Us
                </Nav.Link>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default NavBar
