import { Container, Image, Row, Col } from "react-bootstrap"
import walletIcon from "../../assets/images/ventix.png"
import ventixWallet from "../../assets/images/ventixWallet.png"
import LearnMoreButton from "../components/LearnMoreBtn"
import { Fade } from "react-awesome-reveal"

export default function Wallet() {
  return (
    <div
      className={"wallet-bkg"}
      id={"wallet"}
    >
      <Container className={" text-white"}>
        <Fade>
          <Row>
            <Col
              lg={6}
              className="mt-5"
            >
              <h1 className="text-xl mb-4 fs-1">
                Explore Our Cutting-Edge Financial Solutions!
              </h1>
              <p className="text-lg fs-4">
                Unlock a world of convenience and security with our innovative
                range of financial services tailored to meet your every need.
              </p>
              <div className={"row py-2"}></div>
            </Col>
            <Col
              lg={6}
              className="mt-5"
            >
              <div>
                <Image
                  fluid={true}
                  src={ventixWallet}
                  width={1600}
                  height={1600}
                  alt="ventix wallet"
                />
              </div>
            </Col>
          </Row>
          <Row className="pt-5 mt-5">
            <Col className="d-flex justify-content-center align-items-center">
              <LearnMoreButton to="/wallet">Learn More</LearnMoreButton>
            </Col>
          </Row>
        </Fade>
      </Container>
    </div>
  )
}
