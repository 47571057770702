import React from "react"
import { Form } from "react-bootstrap"

const CustomInput = ({
  type,
  label,
  placeholder,
  value,
  onChange,
  ...rest
}) => {
  return (
    <Form.Group controlId="formBasicInput">
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </Form.Group>
  )
}

export default CustomInput
